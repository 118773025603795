<template>
  <div>
    <page-header :title="title"></page-header>
    <form @submit.prevent="gravar">
      <div class="modal-body">
        <div class="form-horizontal">
          <vue-element-loading
            :active="isActive"
            spinner="bar-fade-scale"
            color="#428BCA"
          ></vue-element-loading>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="cpf">
              CPF</label
            >
            <div class="col-sm-9">
              <the-mask
                class="col-xs-10 col-sm-3 cpf"
                type="text"
                name="usuario"
                :mask="['###.###.###-##']"
                v-model="item.cpf"
                placeholder="CPF"
                required=""
                :disabled="$route.params.id"
              ></the-mask>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="senha">
              Senha</label
            >
            <div class="col-sm-9">
              <input
                class="col-xs-10 col-sm-2"
                type="password"
                v-model="item.password"
                placeholder="Senha"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="senha2">
              Repetir Senha</label
            >
            <div class="col-sm-9">
              <input
                class="col-xs-10 col-sm-2"
                type="password"
                v-model="item.senha2"
                placeholder="Repetir Senha"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="email">
              E-mail</label
            >
            <div class="col-sm-9">
              <input
                class="col-xs-10 col-sm-8"
                type="email"
                v-model="item.email"
                placeholder="E-mail"
                required=""
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="nome">
              Nome</label
            >
            <div class="col-sm-9">
              <input
                class="col-xs-10 col-sm-8"
                type="text"
                v-model="item.name"
                placeholder="Nome"
                required=""
              />
            </div>
          </div>
          <div class="form-group">
            <select-form
              v-model="item.id_grupo"
              name="Perfil"
              url="/perfil"
              id="id"
              titulo="titulo"
              layout="true"
            ></select-form>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="perfil">
              Permissão</label
            >
            <div class="col-sm-9">
              <ul v-for="(m, i) in menu" :Key="i">
                <li v-if="menu.length">
                  <label
                    ><input type="checkbox" v-model="m.visualizar" />{{
                      m.titulo
                    }}</label
                  >
                  <ul v-for="(sub, ks) in m.submenu" :key="ks">
                    <li>
                      <label
                        ><input type="checkbox" v-model="sub.visualizar" />{{
                          sub.titulo
                        }}
                        <ul v-for="(act, ia) in sub.actions" :key="ia">
                          <li>
                            <label
                              ><input type="checkbox" v-model="act.value" />{{
                                act.name
                              }}</label
                            >
                          </li>
                        </ul>
                      </label>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <select-form
              v-model="item.bloquear"
              :value="item.bloquear"
              name="Ativo"
              url="/arquivos"
              id="id"
              titulo="nome"
              layout="true"
            ></select-form>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="submit">Gravar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import { TheMask } from 'vue-the-mask'
import SelectForm from '@/components/form/SelectForm'
import MenuDefault from '@/modules/menu'
import req from '@/components/request'
import { sucesso, erro } from '@/components/Message'

export default {
  components: {
    VueElementLoading,
    PageHeader,
    TheMask,
    SelectForm
  },
  watch: {
    'item.id_grupo'() {
      this.getPerfil()
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      title: 'Usuários',
      item: {
        name: '',
        email: '',
        password: '',
        senha2: '',
        cpf: '',
        bloquear: '',
        id_grupo: ''
      },
      menu: [],
      isActive: false
    }
  },
  methods: {
    ...mapActions(['getLogsTotal']),
    mergeRecursive(obj1, obj2) {
      for (var p in obj2) {
        try {
          if (obj2[p].constructor === Object) {
            obj1[p] = this.mergeRecursive(obj1[p], obj2[p])
          } else {
            obj1[p] = obj2[p]
          }
        } catch (e) {
          obj1[p] = obj2[p]
        }
      }

      return obj1
    },
    getUsuario() {
      req(`/usuarios/${this.$route.params.id}`)
        .then(resp => {
          if (resp.length) {
            this.item = { ...resp[0] }
            this.isActive = false
            this.getMenu()
          }
        })
        .catch(response => {
          console.log('error: ' + response)
          erro()
        })
    },
    getPerfil() {
      this.$axios(`/perfil/${this.item.id_grupo}`).then(resp => {
        if (resp.data.id) {
          this.menu = [...JSON.parse(resp.data.menu)]
        }
      })
    },
    getMenu() {
      this.$axios('/menu', {
        params: { id: this.$route.params.id }
      }).then(resp => {
        if (resp.data.length) {
          this.menu = [...JSON.parse(resp.data[0].menu)]
        }
      })
    },
    getCheck(item) {
      if (item.submenu.length) {
        item.submenu.map(s => {
          s.visualizar = !item.visualizar
          if (s.actions.length) {
            s.actions.map(a => {
              a.value = !item.visualizar
              return a
            })
          }
          return s
        })
      }
    },
    getCheckSub(item) {
      if (item.actions.length) {
        item.actions.map(a => {
          a.value = !item.visualizar
          return a
        })
      }
    },
    gravar() {
      let acessos = this.menu ? JSON.stringify(this.menu) : null
      let itens = this.item
      itens.USER = this.user.id
      if (this.$route.params.id) {
        req(`/usuarios/${this.$route.params.id}`, 'put', {
          itens,
          acessos
        })
          .then(() => {
            sucesso('Atualizado...')
            this.getLogsTotal(this.user.id)
            this.$router.push('/admin/usuarios')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      } else {
        req('/usuarios', 'post', {
          itens,
          acessos
        })
          .then(() => {
            sucesso('Atualizado...')
            this.getLogsTotal(this.user.id)
            this.$router.push('/admin/usuarios')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      }
    }
  },
  created() {
    this.menu = MenuDefault
    if (this.$route.params.id) {
      this.isActive = true
      this.getUsuario()
    }
  }
}
</script>

<style lang="stylus" scoped></style>
